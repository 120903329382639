<template>

    <v-card v-if="isAdmin" max-width="700" class="mx-auto glass my-4">
        <v-card-title>

            <v-icon left>
                mdi-tent
            </v-icon>
            Licencias - Total : {{ allLicenses.length }}
        </v-card-title>

        <v-card-text>
            <div v-if="allLicenses.length === 0" class="text-center pa-4">
                <p>No hay licencias registradas</p>
            </div>
            <v-list v-else>
                <v-list-item v-for="(license, index) in allLicenses" :key="index">
                    <v-list-item-content>
                        <v-list-item-title>
                            Licencia solicitada el: {{ license.issuedOn }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Estado: {{ license.isActive ? 'Activa' : `Concluida el : ${license.resumedOn}` }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="license.isActive">
                        <v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" v-bind="attrs" v-on="on" :loading="loading2">
                                    <v-icon left>
                                        mdi-calendar
                                    </v-icon>
                                    Elegir fecha fin
                                </v-btn>
                            </template>
                            <v-date-picker v-model="newLicense.end" @input="resumeLicense(license.id)"></v-date-picker>
                        </v-menu>
                    </v-list-item-action>

                    <v-list-item-action>
                        <v-btn color="error" @click="revertLicense(license.id)" :loading="loading2">
                            <v-icon left>
                                mdi-delete
                            </v-icon>
                            Eliminar
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title v-if="!activeLicense">
            Nueva Licencia
        </v-card-title>
        <v-card-text v-if="!activeLicense">

            <v-menu ref="startMenu" v-model="startMenu" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field filled rounded v-model="newLicense.start" label="Fecha inicio" readonly v-bind="attrs"
                        v-on="on" single-line prepend-inner-icon="mdi-calendar"></v-text-field>
                </template>
                <v-date-picker v-model="newLicense.start" @input="startMenu = false"></v-date-picker>
            </v-menu>
            <v-menu ref="endMenu" v-model="endMenu2" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field filled rounded v-model="newLicense.end" label="Fecha finalizacion (opcional)" readonly
                        v-bind="attrs" v-on="on" single-line prepend-inner-icon="mdi-calendar"></v-text-field>
                </template>
                <v-date-picker v-model="newLicense.end" @input="endMenu2 = false"></v-date-picker>
            </v-menu>
            <v-btn color="primary darken-2" @click="addLicenseForUser" :loading="loading2">
                <v-icon left>
                    mdi-plus
                </v-icon>
                Agregar Licencia
            </v-btn>
        </v-card-text>
        <v-divider></v-divider>

    </v-card>
    <v-card v-else-if="!loading">
        <v-card-title class="pl-8">
            <v-icon left>
                mdi-tent
            </v-icon>
            Licencias
        </v-card-title>
        <v-card-text v-if="licenseCount < 1 || activeLicense == true" class="">
            <p v-if="!activeLicense">Has tenido {{ licenseCount }} licencias este año.</p>
            <p v-else>Actualmente tienes una licencia activa.</p>

            <v-btn large block v-if="activeLicense" color="primary darken-2" @click="resumeLicense()"
                :loading="loading2">
                <v-icon left>
                    mdi-stop
                </v-icon>
                Finalizar licencia</v-btn>
            <v-btn block v-else color="primary darken-2" @click="requestLicense" :loading="loading2">
                <v-icon left>
                    mdi-play
                </v-icon>
                Iniciar licencia</v-btn>
        </v-card-text>
        <v-card-text v-else>
            <p>Has tenido {{ licenseCount }} licencias este año. No puedes solicitar más licencias.</p>
        </v-card-text>
    </v-card>
    <v-progress-linear v-else indeterminate></v-progress-linear>
</template>
<script>
import {
    getFirestore,
    doc,
    collection,
    addDoc,
    updateDoc,
    serverTimestamp,
    getDocs,
    increment,
    query,
    where,
    Timestamp,
    getDoc,
    deleteDoc,


} from 'firebase/firestore';

import moment from 'moment';


export default {
    name: 'LicenseManager',
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            licenseCount: 0,
            loading2: false,
            activeLicense: false,
            isAdmin: this.$store.state.Auth.token.claims.type == "superuser" || this.$store.state.Auth.token.claims.type == "admin" || this.$store.state.Auth.token.claims.type == "entrenador",
            newLicense: {
                start: null,
                end: null,
            },
            startMenu: false,
            endMenu: false,
            endMenu2: false,
            allLicenses: [], // Array to store all licenses

        };
    },
    async mounted() {
        await this.loadUserData();
        this.loading = false;
    },
    methods: {
        async addLicenseForUser() {
            if (!this.newLicense.start) {
                //this.$alert('Start date is required.'); // Or use Vuetify's snackbar

                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Fecha de inicio requerida.',
                    type: 'error',
                });

                return;
            }

            try {
                this.loading2 = true;
                const db = getFirestore();
                const licensesColRef = collection(db, 'users', this.$props.user.id, 'licenses');
                const userRef = doc(db, 'users', this.$props.user.id);

                // Prepare the license data
                const newLicenseData = {
                    issuedOn: Timestamp.fromDate(moment(this.newLicense.start, 'YYYY-MM-DD').toDate()),
                    isActive: !this.newLicense.end, // Active if no end date
                    resumedOn: this.newLicense.end ? Timestamp.fromDate(moment(this.newLicense.end, 'YYYY-MM-DD').toDate()) : null,
                };

                // Add the new license
                await addDoc(licensesColRef, newLicenseData);

                // Update user's document
                if (this.newLicense.end) {
                    // If license is resumed, calculate the duration and update endOfSubscription
                    const issuedOnMoment = moment(this.newLicense.start, 'YYYY-MM-DD');
                    const resumedOnMoment = moment(this.newLicense.end, 'YYYY-MM-DD');
                    const durationInDays = resumedOnMoment.diff(issuedOnMoment, 'days');
                    const userSnap = await getDoc(userRef);
                    const user = userSnap.data();

                    if (user.endOfSubscription) {
                        const currentEndMoment = moment(new Date(user.endOfSubscription.seconds * 1000));
                        const newEndMoment = currentEndMoment.add(durationInDays, 'days');
                        const newEndTimestamp = Timestamp.fromDate(newEndMoment.toDate());

                        await updateDoc(userRef, {
                            endOfSubscription: newEndTimestamp,
                        });


                        this.$emit('updateEndOfSubscription', newEndTimestamp)
                    }




                } else {
                    // If license is not resumed, set licensePaused to true
                    await updateDoc(userRef, {
                        licensePaused: true
                    });

                }

                // Reset form fields and notify the user
                this.newLicense.start = null;
                this.newLicense.end = null;
                //this.$alert('License successfully added.');
                this.$notify({
                    group: 'feedback',
                    title: 'Exito',
                    text: 'Licencia agregada con exito.',
                    type: 'success',
                });
                this.loadUserData()
            } catch (error) {
                //this.$alert('Error adding license. Please try again.');
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Error agregando licencia. Por favor intente nuevamente.',
                    type: 'error',
                });
            } finally {
                this.loading2 = false;
            }
        },
        async requestLicense() {
            if (this.activeLicense) return;

            let confirm = await this.$confirm("¿Está seguro que desea iniciar una licencia? Este proceso no se puede deshacer y tiene un maximo de 1 licencia por año. Las mismas son autogestionadas por el usuario sin intervención de la empresa. Al iniciar una licencia no podra anotarse a clases hasta el dia que regrese y finalice la misma.",
                {
                    color: "error",
                    title: "Iniciar licencia",
                    buttonTrueText: "Confirmar",
                }
            )

            if (!confirm) {
                return;
            }


            try {
                this.loading2 = true;
                const db = getFirestore();
                const licensesColRef = collection(db, 'users', this.$props.user.id, 'licenses');

                // Add a new license document in the licenses subcollection
                const licenseRef = await addDoc(licensesColRef, {
                    issuedOn: serverTimestamp(),
                    isActive: true,
                    resumedOn: null,
                });

                // Optionally, increment the license count in the user's document
                const userRef = doc(db, 'users', this.$props.user.id);


                //update user license status on the user document
                await updateDoc(userRef, {
                    licensePaused: true
                });

                this.activeLicense = true;

                this.licenseCount += 1;

            } catch (error) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Error iniciando licencia. Por favor intente nuevamente.',
                    type: 'error',
                });
            } finally {
                this.loading2 = false;
            }
        },
        async resumeLicense(resumeLicenseID = null) {

            if (!resumeLicenseID && !this.activeLicense) return;

            try {
                this.loading2 = true;
                const db = getFirestore();
                const licensesColRef = collection(db, 'users', this.$props.user.id, 'licenses');

                // Query for the active license
                const queryActive = query(licensesColRef, where("isActive", "==", true));
                const activeSnapshots = await getDocs(queryActive);

                if (activeSnapshots.empty) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Error',
                        text: 'No hay licencias activas para reanudar.',
                        type: 'error',
                    });
                    return; // No active licenses to resume
                }

                // Assuming only one active license at a time
                const lastActiveLicenseDoc = activeSnapshots.docs[0];
                const lastActiveLicense = lastActiveLicenseDoc.data();

                let licenseRef;
                let resumedOnDate;

                if (resumeLicenseID) {
                    licenseRef = doc(db, 'users', this.$props.user.id, 'licenses', resumeLicenseID);
                    resumedOnDate = Timestamp.fromDate(moment(this.newLicense.end, 'YYYY-MM-DD').toDate());
                } else {
                    // Update the license document to set the resumedOn field
                    licenseRef = doc(db, 'users', this.$props.user.id, 'licenses', lastActiveLicenseDoc.id);
                    resumedOnDate = Timestamp.fromDate(moment().toDate());
                }

                await updateDoc(licenseRef, {
                    resumedOn: resumedOnDate,
                    isActive: false,
                });

                // Update the endOfSubscription in the user's document
                const userRef = doc(db, 'users', this.$props.user.id);
                const userSnap = await getDoc(userRef);
                const user = userSnap.data();

                if (user.endOfSubscription) {
                    // Calculate the duration of the license
                    const issuedOnMoment = moment(new Date(lastActiveLicense.issuedOn.seconds * 1000));

                    const resumedOnMoment = moment(new Date(resumedOnDate.seconds * 1000)
                    );
                    const durationInDays = resumedOnMoment.diff(issuedOnMoment, 'days');

                    // Add this duration to the current endOfSubscription
                    const currentEndMoment = moment(
                        user.endOfSubscription.toDate() ||
                        new Date(user.endOfSubscription.seconds * 1000)
                    );
                    const newEndMoment = currentEndMoment.add(durationInDays, 'days');
                    const newEndTimestamp = Timestamp.fromDate(newEndMoment.toDate());

                    await updateDoc(userRef, {
                        endOfSubscription: newEndTimestamp,
                        licensePaused: false,
                    });

                    this.$emit('updateEndOfSubscription', newEndTimestamp);
                }

                this.activeLicense = false;
                this.newLicense.end = null;
                this.endMenu = false;

            } catch (error) {
                console.error(error);
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Error finalizando licencia. Por favor intente nuevamente.',
                    type: 'error',
                });
            } finally {
                this.loading2 = false;
            }
        }
        ,
        async loadUserData() {
            this.licenseCount = 0;
            this.activeLicense = false;
            this.allLicenses = [];

            // Implement logic to load the current license count and active license status
            // This might involve querying the licenses subcollection and counting the documents or fetching a license count from the user's document
            const db = getFirestore();
            const licensesColRef = collection(db, 'users', this.$props.user.id, 'licenses');
            const licenseSnapshots = await getDocs(licensesColRef);
            // set allLicenses with corresponding objects

            const currentYear = new Date().getFullYear();

            this.allLicenses = licenseSnapshots.docs.map(doc => {
                const data = doc.data();
                if (data.issuedOn.toDate().getFullYear() === currentYear) {
                    this.licenseCount += 1;
                }

                if (data.isActive) {
                    this.activeLicense = true;
                }
                return {
                    id: doc.id,
                    issuedOnDateOBJ: data.issuedOn,
                    issuedOn: data.issuedOn.toDate(),
                    isActive: data.isActive,
                    resumedOnDateOBJ: data.resumedOn,
                    resumedOn: data.resumedOn ? data.resumedOn.toDate() : null,
                };
            });

            //sort licenses by issuedOn date
            this.allLicenses.sort((a, b) => {
                return b.issuedOn - a.issuedOn;
            });

            // Update local state based on the loaded data
            this.activeLicense = this.$props.user.licensePaused;
        },
        generateUniqueId() {
            return Math.random().toString(36).substr(2, 9);
        },
        async revertLicense(licenseID) {
            let confirm = await this.$confirm("¿Está seguro que desea eliminar esta licencia?",
                {
                    color: "error",
                    title: "Eliminar licencia",
                    buttonTrueText: "Confirmar",
                }
            )

            if (!confirm) {
                return;
            }

            try {
                this.loading2 = true;

                const db = getFirestore();
                // here must manage the licensePaused field on the user document also the endOfSubscription field if  the license is active or not.

                const userRef = doc(db, 'users', this.$props.user.id);
                const userSnap = await getDoc(userRef);
                const user = userSnap.data();

                let currentLicense = this.allLicenses.filter(license => license.id == licenseID)[0];

                if (currentLicense.isActive) {
                    // If license is active, set licensePaused to false
                    await updateDoc(userRef, {
                        licensePaused: false
                    });
                } else {
                    // If license is not active, calculate the duration and update endOfSubscription
                    const issuedOnMoment = moment(new Date(currentLicense.issuedOnDateOBJ.seconds * 1000));
                    const resumedOnMoment = moment(new Date(currentLicense.resumedOnDateOBJ.seconds * 1000));
                    const durationInDays = resumedOnMoment.diff(issuedOnMoment, 'days');

                    if (user.endOfSubscription) {
                        const currentEndMoment = moment(new Date(user.endOfSubscription.seconds * 1000));
                        const newEndMoment = currentEndMoment.subtract(durationInDays, 'days');
                        const newEndTimestamp = Timestamp.fromDate(newEndMoment.toDate());

                        await updateDoc(userRef, {
                            endOfSubscription: newEndTimestamp,
                        });
                        this.$emit('updateEndOfSubscription', newEndTimestamp)

                    }

                }

                //then licenses subcollection
                const licenseRef = doc(db, 'users', this.$props.user.id, 'licenses', licenseID);
                await deleteDoc(licenseRef);
                this.allLicenses = this.allLicenses.filter(license => license.id != licenseID);
                this.$notify({
                    group: 'feedback',
                    title: 'Exito',
                    text: 'Licencia eliminada con exito.',
                    type: 'success',
                });
                this.loadUserData()
            } catch (error) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Error eliminando licencia. Por favor intente nuevamente.',
                    type: 'error',
                });
            } finally {
                this.loading2 = false;
            }
        },
    },
};
</script>
